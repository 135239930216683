
export default {
  methods: {},
  head() {
    return {
      title: 'Home',
    }
  },
  mounted() {},
}
